import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import { Button, Col, Input, Form, Result, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { animated, useSpring } from 'react-spring';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';
import PrismicDOM from 'prismic-dom';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const getBase64File = (file, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(file);
};

const Contact = () => {
	const { t } = useTranslation();
	const [ form ] = Form.useForm();
	const [ jobForm ] = Form.useForm();
	const [ state, setState ] = useState('waiting');
	const fadeForm = useSpring({ position: 'static', opacity: state === 'waiting' ? 1 : 0 });
	const fadeSuccess = useSpring({ position: 'absolute', opacity: state === 'success' ? 1 : 0 });
	const fadeError = useSpring({ position: 'absolute', opacity: state === 'error' ? 1 : 0 });
	const [ stateJob, setStateJob ] = useState('waiting');
	const [ attachments, setAttachments ] = useState([]);
	const fadeFormJob = useSpring({ position: 'static', opacity: stateJob === 'waiting' ? 1 : 0 });
	const fadeSuccessJob = useSpring({ position: 'absolute', opacity: stateJob === 'success' ? 1 : 0 });
	const fadeErrorJob = useSpring({ position: 'absolute', opacity: stateJob === 'error' ? 1 : 0 });
	const [ contact, setContact ] = useState({ title: '', legume_image: '', content: '' });
	const [ infos, setInfos ] = useState({ title: '', content: '' });

	const data = useStaticQuery(graphql`
	    query {
	      imageContact: file(relativePath: { eq: "contact.jpg" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X6EvkxAAACEAY3CA', { lang : 'fr-ch' })
		).then(response => {
			setContact({
				title: response.data.title,
				legume_image: response.data.image_legume,
				content: response.data.content
			});

			setInfos({
				title: response.data.title_contact,
				content: response.data.infos_contact
			});
		});
	}, []);

	const handleSubmit = async values => {
		setState('loading');

		const formData = new FormData();
		Object.keys(values).forEach(key => {
			formData.append(key, values[key]);
		});

		const req = await fetch('/api/contact', {
			method: 'POST',
			headers: { 'Accept': 'application/json' },
			body: formData
		});
		const res = await req.json();

		if (res.success) {
			setState('success');
			form.resetFields();
		}
		else {
			setState('error');

			setTimeout(() => {
				setState('waiting');
			}, 5000);
		}
	};

	const handleSubmitJob = async values => {
		setStateJob('loading');

		const formData = new FormData();
		Object.keys(values).forEach(key => {
			formData.append(key, values[key]);
		});
		attachments.forEach(file => {
			formData.append('files[]', file);
		});

		const req = await fetch('/api/contact-job', {
			method: 'POST',
			headers: { 'Accept': 'application/json' },
			body: formData
		});
		const res = await req.json();

		if (res.success) {
			setStateJob('success');
			jobForm.resetFields();
		}
		else {
			setStateJob('error');

			setTimeout(() => {
				setStateJob('waiting');
			}, 5000);
		}
	};

	return (
		<AppLayout>
			<SEO title="Contact" />

			<div className="hero-image">
				<Img fluid={data.imageContact.childImageSharp.fluid} />
			</div>

			<div>
				<div className="page-section">
					<div className="section-title">
						<h2>
							{contact.title.length ? contact.title[0].text : ''}
							{contact.legume_image.url ? (
								<img src={contact.legume_image.url} alt={contact.title.length ? contact.title[0].text : ''} />
							) : null}
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-content">
						<div className="section-content-text">
							<Row gutter={[32, 32]} justify="space-between" style={{ width: '100%' }}>
								<Col xs={24} lg={6} xxl={4}>
									<h2 style={{ color: '#6DA651' }}>{infos.title.length ? infos.title[0].text : ''}</h2>
									{infos.content.length ? (
										<div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(infos.content) }} />
									) : null}
								</Col>
								<Col xs={24} md={12} lg={8}>
									<h3>{t('CONTACT_FORM')}</h3>
									<animated.div style={fadeSuccess}>
										<Result
											status="success"
											title={t('THANKS_MESSAGE_SENT')}
										/>
									</animated.div>
									<animated.div style={fadeError}>
										<Result
											status="error"
											title={t('ERROR_SENDING_MESSAGE')}
										/>
									</animated.div>
									<animated.div style={fadeForm}>
										<Form form={form} onFinish={handleSubmit}>
											<Form.Item
												name="fullname"
												rules={[{ required: true, message: t('ENTER_FIRST_LAST_NAME') }]}
											>
												<Input disabled={state === 'loading'} placeholder={t('FULL_NAME')} />
											</Form.Item>
											<Form.Item
												name="email"
												rules={[{ required: true, message: t('ENTER_EMAIL') }]}
											>
												<Input disabled={state === 'loading'} placeholder={t('EMAIL')} />
											</Form.Item>
											<Form.Item name="phone">
												<Input disabled={state === 'loading'} placeholder={t('PHONE')} />
											</Form.Item>
											<Form.Item
												name="message"
												rules={[{ required: true, message: t('WRITE_MESSAGE') }]}
											>
												<Input.TextArea disabled={state === 'loading'} autoSize={{ minRows: 4, maxRows: 8 }} placeholder={t('MESSAGE')} />
											</Form.Item>
											<Form.Item style={{ textAlign: 'right' }}>
												<Button type="primary" loading={state === 'loading'} htmlType="submit">{t('SEND')}</Button>
											</Form.Item>
										</Form>
									</animated.div>
								</Col>
								<Col xs={24} md={12} lg={8}>
									<h3>{t('JOB_FORM')}</h3>
									<animated.div style={fadeSuccessJob}>
										<Result
											status="success"
											title={t('THANKS_MESSAGE_JOB_SENT')}
										/>
									</animated.div>
									<animated.div style={fadeErrorJob}>
										<Result
											status="error"
											title={t('ERROR_SENDING_MESSAGE_JOB')}
										/>
									</animated.div>
									<animated.div style={fadeFormJob}>
										<Form form={jobForm} onFinish={handleSubmitJob}>
											<Form.Item
												name="fullname"
												rules={[{ required: true, message: t('ENTER_FIRST_LAST_NAME') }]}
											>
												<Input disabled={stateJob === 'loading'} placeholder={t('FULL_NAME')} />
											</Form.Item>
											<Form.Item
												name="email"
												rules={[{ required: true, message: t('ENTER_EMAIL') }]}
											>
												<Input disabled={stateJob === 'loading'} placeholder={t('EMAIL')} />
											</Form.Item>
											<Form.Item
												name="phone"
												rules={[{ required: true, message: t('ENTER_PHONE') }]}
											>
												<Input disabled={stateJob === 'loading'} placeholder={t('PHONE')} />
											</Form.Item>
											<Form.Item>
												<Upload
													action={null}
													customRequest={({ file, onSuccess }) => {
														setTimeout(() => {
															onSuccess('ok');
														}, 0);
													}}
													accept=".pdf,.odt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
													transformFile={file => {
														setAttachments(state => {
															const tmp = [ ...state ];
															tmp.push(file);
															return tmp;
														});
													}}
													onRemove={file => {
														setAttachments(state => {
															const tmp = [ ...state ];
															tmp.splice(tmp.findIndex(f => f.uid === file.uid), 1);
															return tmp;
														});
													}}
												>
													<Button icon={<UploadOutlined />}>{t('ADD_ATTACHMENT')}</Button>
												</Upload>
											</Form.Item>
											<Form.Item style={{ textAlign: 'right' }}>
												<Button type="primary" loading={stateJob === 'loading'} htmlType="submit">{t('SEND')}</Button>
											</Form.Item>
										</Form>
									</animated.div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default Contact;